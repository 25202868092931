import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as faIcons from '@fortawesome/free-solid-svg-icons'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './map-box.module.scss'
import { useContactInformation } from '../../hooks/useContactInformation'

const MapBox: React.FC = () => {
    const data = useStaticQuery(graphql`
        query {
            mapBackground: file(relativePath: { eq: "map-background.jpg" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    const contactInformation = useContactInformation()
    const { address } = contactInformation

    return (
        <div
            className={styles.container}
            style={{
                backgroundImage: `url(${data.mapBackground.childImageSharp.fluid.src})`,
                backgroundSize: 'cover',
            }}
        >
            <h2>Pour nous joindre</h2>
            <div>
                <span>
                    <FontAwesomeIcon icon={faIcons.faMapPin} />
                </span>
                <p>
                    <a href={address.googleMapUrl} target={'_blank'}>
                        {address.doorNumber} {address.streetName}
                        <br />
                        {address.city}
                        <br />
                        {address.postalCode}
                    </a>
                </p>
            </div>
            <div className={styles.closer}>
                <span>
                    <FontAwesomeIcon icon={faIcons.faPhone} />
                </span>
                <p>
                    <a href={`tel:${contactInformation.phoneNumberUri}`}>{contactInformation.phoneNumber}</a>
                </p>
            </div>
            <div>
                <span>
                    <FontAwesomeIcon icon={faIcons.faEnvelope} />
                </span>
                <p>
                    <a href={`mailto:${contactInformation.contactEmail}`}>{contactInformation.contactEmail}</a>
                </p>
            </div>
            <div>
                <span>
                    <FontAwesomeIcon icon={faIcons.faCalendarAlt} />
                </span>
                <p>
                    Lundi: <span>9h @ 17h</span> <br />
                    Mardi: <span>9h @ 17h</span> <br />
                    Mercredi: <span>9h @ 17h</span> <br />
                    Jeudi: <span>9h @ 17h</span> <br />
                    Vendredi: <span>9h @ 17h</span>
                </p>
            </div>
        </div>
    )
}
export default MapBox

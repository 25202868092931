import React, { FC, useMemo } from 'react'
import MapBox from '../map-box/map-box'
import * as styles from './contact.module.scss'
import ContactForm from './contact-form'

interface Props {
    overlapPreviousElement?: boolean
}

const Contact: FC<Props> = ({ overlapPreviousElement = false }) => {
    const innerStyle = useMemo(() => (overlapPreviousElement ? null : { marginTop: 0, paddingTop: 40 }), [
        overlapPreviousElement,
    ])

    return (
        <div className={styles.contact_section}>
            <div className={styles.contact_section_inner} style={innerStyle}>
                <div className={styles.map_box}>
                    <MapBox />
                </div>
                <div className={styles.contact_form}>
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default Contact

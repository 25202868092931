import React from 'react'
import Layout from '../components/layouts/layout'
import mobileBackground from '../images/banner-logo-with-light-mobile.jpg'
import desktopBackground from '../images/banner-logo-with-light-desktop.jpg'
import { Colors } from '../colors'
import { Hero } from '../components/hero/hero'
import Contact from '../components/blocs/contact'
import { useLayoutInformation } from '../hooks/useLayoutInformation'

const NotFoundPage = () => {
    const { isMobile, secondaryColor } = useLayoutInformation(Colors.lightausBlue)

    return (
        <Layout backgroundColor={Colors.lightausBlue} titleIntlId="Page non-existante">
            <Hero
                backgroundImageUri={isMobile ? mobileBackground : desktopBackground}
                backgroundPosition="bottom"
                message={`Nous n'avons pas trouvé la page que vous recherchez, mais nous pouvons vous aider à prendre des décisions financières éclairées.`}
                buttonColor={secondaryColor}
                fullWidthContent
            />
            <Contact overlapPreviousElement />
        </Layout>
    )
}

export default NotFoundPage

import * as React from 'react'
import { Form, Formik, FormikActions } from 'formik'
import { FormInput } from '../components/form-input/form-input'
import { SubmitButton } from '../components/submit-button/submit-button'
import { contactFormValidationSchema } from '../validation/contact-form-validation-schema'
import Reaptcha from 'reaptcha'
import * as localStyles from './contact-form.module.scss'
import { sendEmail, TemplateType } from '../connectivity/emailjs'

export interface FormValues {
    name: string
    email: string
    tel: string
    message: string
    recaptcha: string
}

export interface Props {
    onSubmit?: (values: FormValues, formikActions: FormikActions<FormValues>) => void
    styles?: any
}

export const defaultSubmitHandler = (values: FormValues, actions: FormikActions<FormValues>) => {
    const { email, name, message, tel } = values
    const { setSubmitting, setStatus } = actions

    sendEmail({ from_name: name, message_html: message, reply_to: email, phone_number: tel, template: TemplateType.Contact })
        .then(result => {
            setStatus({
                status: result.error ? 'error' : 'success',
                message: result.message,
            })
            setSubmitting(false)
        })
        .catch(e => {
            console.log(e)
            setStatus({
                status: 'error',
                message: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
            })
            setSubmitting(false)
        })
}

export const ContactForm: React.FC<Props> = ({ onSubmit = defaultSubmitHandler, styles = {} }) => {
    const recaptchaRef = React.createRef<Reaptcha>()

    styles = {
        ...localStyles,
        ...styles,
    }

    return (
        <>
            <Formik
                onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
                    onSubmit(values, actions)
                    recaptchaRef.current.reset()
                }}
                initialValues={{ name: '', email: '', tel: '', message: '', recaptcha: '' }}
                validationSchema={contactFormValidationSchema}
                render={({ isSubmitting, isValid, status, setFieldValue }) => {
                    if (status && status.status === 'success') {
                        return <p>{status.message}</p>
                    }

                    return (
                        <Form>
                            <FormInput name="name" label="Votre nom (requis)" type="text" />
                            <FormInput name="email" label="Votre courriel" type="email" size="half" />
                            <FormInput name="tel" label="Votre téléphone" type="text" size="half" />
                            <FormInput name="emailOrTel" label="emailOrTel" type="hidden" />
                            <p className={styles.field_message}>
                                Remplissez au moins l'un des champs courriel ou téléphone.
                            </p>
                            <FormInput name="message" label="Votre message (requis)" component="textarea" />
                            {status && status.status === 'error' && <p>{status.message}</p>}

                            <div className={styles.footer}>
                                <div>
                                    <Reaptcha
                                        sitekey="6LcOV74UAAAAADAcioMlg0O_HFO7L0XQ1MvAPIDS"
                                        hl={'fr'}
                                        ref={recaptchaRef}
                                        onVerify={() => {
                                            setFieldValue('recaptcha', 'verified')
                                        }}
                                        onError={() => {
                                            setFieldValue('recaptcha', '')
                                        }}
                                        onExpire={() => {
                                            setFieldValue('recaptcha', '')
                                        }}
                                    />
                                </div>
                                <SubmitButton disabled={isSubmitting || !isValid} />
                            </div>
                            <div style={{ clear: 'both' }} />
                        </Form>
                    )
                }}
            />
        </>
    )
}

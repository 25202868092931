import React from 'react'
import { ContactForm as ContactFormComponent } from '../../forms/contact-form'
import * as styles from './contact-form.module.scss'

const ContactForm: React.FC = () => {
    return (
        <div className={styles.container}>
            <h2>Contactez-nous</h2>
            <ContactFormComponent styles={styles} />
        </div>
    )
}

export default ContactForm
